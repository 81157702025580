import styles from "./PageImageBackSplash.module.css";

type BaseProps = {
  image: string;
  imageDescription: string;
  color?: string;
};

type Props = BaseProps &
  (
    | { title: string; description?: string }
    | { title?: never; description?: never }
  );

const PageImageBackSplash = ({
  image,
  imageDescription,
  title,
  description,
  color = "#000",
}: Props) => {
  return (
    <div>
      <img src={image} alt={imageDescription} className={styles.image} />;
      {title && (
        <div className={styles.titleBanner} style={{ color }}>
          <h1 className={styles.titleHeader}>{title}</h1>
          <p className={styles.titleDescription}>{description}</p>
        </div>
      )}
    </div>
  );
};

export default PageImageBackSplash;
