import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import ComingSoon from "./ComingSoon";
import { hasFeature } from "../utils/featureFlags";

import styles from "./App.module.css";

function App() {
  const isDeveloper = hasFeature("developer");

  useEffect(() => {
    if (!isDeveloper) {
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
    }
  }, [isDeveloper]);

  return (
    <div className={styles.wrapper}>
      <Header isDeveloper={isDeveloper} />

      {isDeveloper ? (
        <>
          <Outlet />
          <Footer />
        </>
      ) : (
        <ComingSoon />
      )}
    </div>
  );
}

export default App;
