import PageImageBackSplash from "./PageImageBackSplash";
import servicesImage from "../assets/images/services.jpg";

import styles from "./Services.module.css";

const Services = () => {
  return (
    <div className={styles.wrapper}>
      <PageImageBackSplash image={servicesImage} imageDescription="Services" />
    </div>
  );
};

export default Services;
