import logo from "../assets/images/White in Black.png";

import styles from "./CompanyLogo.module.css";

const CompanyLogo = () => {
  return (
    <div className={styles.logoWrapper}>
      <img src={logo} alt="logo" className={styles.logo} />
    </div>
  );
};

export default CompanyLogo;
