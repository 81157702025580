import {
  PRIMARY_EMAIL,
  LINKEDIN,
  INSTAGRAM,
  FACEBOOK,
  FOOTER_COMPANY_DESCRIPTION
} from "../constants/company";
import NavigationLinks from "./NavigationLinks";
import Icons from "./ui/Icons";
import CompanyLogo from "./CompanyLogo";

import styles from "./Footer.module.css";

const ICON_SIZE = "3em";

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <CompanyLogo />

        <p className={styles.businessDescription}>
          {FOOTER_COMPANY_DESCRIPTION}
        </p>
      </div>
      <div className={styles.rightLayout}>
        <div className={styles.linksWrapper}>
          <h3 className={styles.linksTitle}>LINKS</h3>
          <NavigationLinks className={styles.navLinks} />
          <div className={styles.socialsWrapper}>
            <a href={FACEBOOK} target="__blank" rel="noreferrer noopener">
              <Icons name="Facebook" height={ICON_SIZE} width={ICON_SIZE} />
            </a>
            <a href={INSTAGRAM} target="__blank" rel="noreferrer noopener">
              <Icons name="Instagram" height={ICON_SIZE} width={ICON_SIZE} />
            </a>
            <a href={`mailto:${PRIMARY_EMAIL}`}>
              <Icons name="Mail" height={ICON_SIZE} width={ICON_SIZE} />
            </a>

            <a href={LINKEDIN} target="__blank" rel="noreferrer noopener">
              <Icons name="LinkedIn" height={ICON_SIZE} width={ICON_SIZE} />
            </a>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Footer;
