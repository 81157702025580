import PageImageBackSplash from "./PageImageBackSplash";
import aboutImage from "../assets/images/about.jpg";

import styles from "./About.module.css";

const About = () => {
  return (
    <div className={styles.wrapper}>
      <PageImageBackSplash image={aboutImage} imageDescription="About us" />
    </div>
  );
};

export default About;
