export const NAME = "SMB Software Solutions";
export const PRIMARY_PHONE_NUMBER = "916-597-0371";
export const PRIMARY_EMAIL = "bruksbmsoft@gmail.com";
export const INSTAGRAM = "https://www.instagram.com/elpis_bookkeeping/";
export const FACEBOOK =
  "https://www.hostinger.com/tutorials/how-to-fix-error-404";
export const LINKEDIN =
  "https://www.linkedin.com/company/elpis-bookkeeping/about/";
export const CALENDLY_LINK = "https://calendly.com/elpisbookkeeping/30min";

export const FOOTER_COMPANY_DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
