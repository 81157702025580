import classnames from "classnames";

import useIsScrolled from "../hooks/useIsScrolled";
import NavigationLinks from "./NavigationLinks";
import CompanyLogo from "./CompanyLogo";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

import styles from "./Header.module.css";

type Props = {
  isDeveloper?: boolean;
};

const Header = ({ isDeveloper }: Props) => {
  const [isScrolled] = useIsScrolled();
  return (
    <div
      className={classnames(styles.header, isScrolled && styles.scrolledHeader)}
    >
      <Link to={ROUTES.home} className={styles.logo}>
        <CompanyLogo />
      </Link>

      {isDeveloper ? (
        <NavigationLinks
          allowToCollapse
          className={classnames(
            styles.navWrapper,
            isScrolled && styles.scrolledNavWrapper
          )}
        />
      ) : (
        <h1>Coming Soon!</h1>
      )}
    </div>
  );
};

export default Header;
