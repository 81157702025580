// @ts-ignore
import video from "../assets/videos/smbComingSoon.mp4";

import styles from "./ComingSoon.module.css";

const ComingSoon = () => {
  return (
    <div className={styles.wrapper}>
      <video width="100%" height="auto" autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default ComingSoon;
