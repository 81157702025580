import PageImageBackSplash from "./PageImageBackSplash";
import homeImage from "../assets/images/home.jpg";

import styles from "./Home.module.css";

const Home = () => {
  return (
    <div className={styles.wrapper}>
      <PageImageBackSplash
        image={homeImage}
        imageDescription="Home"
        title="Small & Medium Business Software Solutions"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
      />
    </div>
  );
};

export default Home;
